import React from 'react'

import { COLORS, ID } from 'config/layout'

import Styled from './Footer.style';
import IMAGES from 'assets/images';
import { EMAIL_ADDRESS, FACEBOOK_URL, INSTAGRAM_URL } from 'config/data';

// const year = new Date().getFullYear();

const Footer = () => {
    return (
        <Styled.Footer id={ID.footer}>
            <div>
                <p>
                    <a href="tel:+48512512806" style={{ color: COLORS.orange }}>
                        +48 512 512 806
                    </a>
                </p>
                <p>
                    <a
                        href={`mailto:${EMAIL_ADDRESS}`}
                        style={{ color: COLORS.orange }}
                    >
                        {EMAIL_ADDRESS}
                    </a>
                </p>
            </div>
            <div>
                <a
                    href={FACEBOOK_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img src={IMAGES.FacebookSvg} width={45} alt="Ikona Facebooka" />
                </a>

                <a
                    href={INSTAGRAM_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Styled.InstaImage
                        src={IMAGES.InstagramSvg}
                        alt="Ikona Instagrama"
                    />
                </a>
            </div>
        </Styled.Footer>
    );
}

export default Footer