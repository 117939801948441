// @ts-nocheck
import React, { useState } from 'react'
import { Link } from 'react-router-dom';

import IMAGES from 'assets/images';
import { ID, ROUTES } from 'config/layout';

import Styled from './HeaderMobile.style';


const HeaderMobile = () => {

    const [menuVisible, setMenuVisible] = useState(false);
    
    return (
        <Styled.Header id={ID.header}>
            <Styled.BackgroundWaveWrapper>
                <Styled.HeaderContentWrapper>
                    <Styled.LogoWrapper>
                        <Link to={ROUTES.home} onClick={() => setMenuVisible(false)}>
                            <Styled.Logo
                                src={IMAGES.LogoLabelSvg}
                                alt="Logo Microscope"
                            />
                        </Link>
                    </Styled.LogoWrapper>

                    <Styled.Menu menuVisible={menuVisible}>
                        <ul>
                            <li>
                                <Link to={ROUTES.spotkaniaWeekendowe} onClick={() => setMenuVisible(false)}>
                                    Spotkania w weekendy
                                </Link>
                            </li>
                            <li>
                                <Link to={ROUTES.zajęcia} onClick={() => setMenuVisible(false)}>
                                    Zajęcia w szkołach i przedszkolach
                                </Link>
                            </li>
                            <li>
                                <Link to={ROUTES.poznajmySie} onClick={() => setMenuVisible(false)}>
                                    Poznajmy się
                                </Link>
                            </li>
                            <li>
                                <Link to={ROUTES.galeria} onClick={() => setMenuVisible(false)}>Galeria</Link>
                            </li>
                            <li>
                                <Link to={ROUTES.kontakt} onClick={() => setMenuVisible(false)}>Kontakt</Link>
                            </li>
                        </ul>
                    </Styled.Menu>

                    <Styled.HamburgerWrapper>
                        <Styled.HamburgerButton onClick={() => setMenuVisible(!menuVisible)} />
                    </Styled.HamburgerWrapper>
                </Styled.HeaderContentWrapper>

                <Styled.WaveBackground>
                    <img src={IMAGES.WavePng} alt="Dekoracja w kształcie fali" />
                </Styled.WaveBackground>
            </Styled.BackgroundWaveWrapper>
        </Styled.Header>
    );
};

export default HeaderMobile;