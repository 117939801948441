// @ts-nocheck
import React from 'react'

import IMAGES from 'assets/images';
import { EMAIL_ADDRESS } from 'config/data';

import { SectionHeader, StandardButton, SubpageWrapper } from 'sections/Sections.style';
import Styled from '../MainPage/MainPage.style';
import { COLORS } from 'config/layout';

const mailtoLinkWarsztaty = `
    mailto:${EMAIL_ADDRESS}?subject=${encodeURIComponent(
    "Warsztaty Microscope"
)}`;

const PoznajmySiePage = () => {

    return (
        <Styled.MainContent id="home">
            <SubpageWrapper>
                <div className="grid-1-a">
                    <div>
                        <SectionHeader color={COLORS.blue} data-aos="fade-up">
                            Poznajmy się!
                        </SectionHeader>

                        <p>
                            Witaj! Być może wiesz, że każde spotkanie z
                            Microscope prowadzę osobiście. Na pewno więc, chcesz
                            wiedzieć kogo zapraszasz do wspólnej naukowej
                            przygody.
                        </p>

                        <p>
                            Jestem naukowcem z zamiłowania i doktorem biochemii
                            z wykształcenia. Skupiam się na tajemnicach
                            mikroświata, który otacza nas każdego dnia. Jednak
                            nawet w świecie molekuł i reakcji chemicznych
                            znajduję czas na jedną z najważniejszych roli mojego
                            życia - bycia mamą dla mojego synka.
                        </p>
                    </div>
                    <div>
                        <img src={IMAGES.CircleGosia} alt="" />
                    </div>
                </div>

                <div className="grid-a-1">
                    <div>
                        <img src={IMAGES.CirclePoznajmySie1} alt="" />
                    </div>
                    <div>
                        <p>
                            Moja naukowa przygoda zaczęła się dzięki niezwykłym
                            nauczycielkom, które spotkałam na swojej drodze. To
                            one zaszczepiły we mnie pasję do odkrywania tajemnic
                            biologii, chemii i fizyki. Teraz, mając tak
                            wspaniałe doświadczenie, pragnę dzielić się tą pasją
                            z najmłodszymi.
                        </p>

                        <p>
                            Pierwsze doświadczenia w popularyzacji nauki wśród
                            dzieci zdobywałam w liceum -opiekując się harcerską
                            drużyną dzieci (w wieku 6-10 lat). Wspólnie
                            eksplorowaliśmy fascynujący świat nauki, przyrody i
                            eksperymentów. Później, w trakcie mojej pracy
                            naukowej, przekazywałem tajniki mojej pracy związaną
                            z komórkami, roztworami i mikroskopami dzieciom i
                            młodzieży poprzez zaangażowanie w projekty Fundacji
                            im. Marcelego Nenckiego.
                        </p>
                    </div>
                </div>

                <div className="grid-1-a">
                    <div>
                        <p>
                            Moje doświadczenie sprawia, że z radością dzielę się
                            wiedzą i pasją z dziećmi, organizując kolorowe,
                            interaktywne i rozwijające warsztaty.
                        </p>

                        <p>
                            Zapraszam Was do odkrywania razem ze mną
                            fascynującego świata nauki, gdzie każdy może być
                            małym odkrywcą mikroświata!
                        </p>

                        <p>
                            Moja oferta obejmuje różnorodne formy warsztatów,
                            dostosowanych do różnych grup wiekowych
                        </p>

                        <div
                            style={{
                                padding: "50px 0 0",
                                textAlign: "center",
                            }}
                        >
                            <a href={mailtoLinkWarsztaty}>
                                <StandardButton
                                    backgroundColor={COLORS.blue}
                                    color={COLORS.cream}
                                >
                                    Spotkajmy się
                                </StandardButton>
                            </a>
                        </div>
                    </div>

                    <div>
                        <img src={IMAGES.CirclePoznajmySie2} alt="" />
                    </div>
                </div>
            </SubpageWrapper>
        </Styled.MainContent>
    );
};

export default PoznajmySiePage