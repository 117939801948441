// @ts-nocheck
/* eslint-disable react/no-unescaped-entities */
import React from 'react'

import IMAGES from 'assets/images';
import { COLORS } from 'config/layout';
import { EMAIL_ADDRESS } from 'config/data';

import Styled from '../MainPage/MainPage.style';
import { SectionHeader, StandardButton, SubpageWrapper } from "sections/Sections.style";


const mailtoLinkWarsztaty = `
    mailto:${EMAIL_ADDRESS}?subject=${encodeURIComponent(
    "Warsztaty Microscope"
)}`;

const ZajeciaPage = () => {
    return (
        <Styled.MainContent>
            <SubpageWrapper>
                <div className="grid-1-a">
                    <div>
                        <SectionHeader color={COLORS.blue} data-aos="fade-up">
                            Zajęcia w szkołach i przedszkolach
                        </SectionHeader>

                        <h3>
                            Zapraszam szkoły i przedszkola do wspólnego
                            odkrywania fascynującego świata nauki, gdzie każdy
                            może być małym odkrywcą mikroświata!
                        </h3>

                        <p>
                            Moja oferta obejmuje różnorodne formy warsztatów,
                            dostosowanych do różnych grup wiekowych – od
                            przedszkolaków (4 latków) do dzieci szkolnych (do 10
                            roku życia).
                        </p>

                        <p>
                            Każde zajęcia prowadzę osobiście – daje to
                            gwarancje, że dzieci są pod opieką odpowiedzialnej
                            osoby. Spotkania z tą samą osobą zapewniają większe
                            poczucie bezpieczeństwa dzieciom – w takich
                            warunkach możliwy jest prawdziwy rozwój!
                        </p>

                        <p>
                            Z przyjemnością zaprojektuję warsztaty tak, aby
                            grupa odkrywała i bawiła się jak najlepiej!
                        </p>

                        <div
                            style={{
                                padding: "50px 0 0",
                                textAlign: "center",
                            }}
                        >
                            <a href={mailtoLinkWarsztaty}>
                                <StandardButton
                                    backgroundColor={COLORS.blue}
                                    color={COLORS.cream}
                                >
                                    Spotkajmy się
                                </StandardButton>
                            </a>
                        </div>
                    </div>

                    <div>
                        <img
                            src={IMAGES.CircleRed}
                            alt=""
                            data-aos="zoom-in"
                            data-aos-delay="300"
                        />
                    </div>
                </div>

                <div className="grid-a-1">
                    <div>
                        <img src={IMAGES.CircleLab} alt="" data-aos="zoom-in" />
                    </div>

                    <div>
                        <SectionHeader
                            color={COLORS.blue}
                            data-aos="fade-up"
                            id="przyrodnicze"
                        >
                            Regularne Warsztaty - Podróż przez Zagadnienia
                            Przyrodnicze
                        </SectionHeader>

                        <p>
                            Cykl warsztatów, podczas których dzieci odkrywają
                            fascynujące tajemnice przyrody. Nauka staje się
                            interaktywną zabawą, a każde spotkanie skupia się na
                            jednym konkretnym zagadnieniu. Realizuję autorski
                            program w czasie zajęć poruszam zagadnienia związane
                            z porami roku, świętami i zjawiskami z którymi
                            dzieci spotykają się na co dzień.
                        </p>

                        <p>
                            Od ciekawych eksperymentów, przez zagadki, po
                            mikroskopowe odkrycia - wszystko to w przyjaznej
                            atmosferze, dostosowanej do wieku uczestników. Nie
                            zabraknie też czasu na ruch lub uspakajające emocje
                            rozciąganie. Podczas cyklu zajęć wspólnie
                            przechodzimy przez różne zagadnienia przyrodnicze,
                            poznając otaczający nas świat.
                        </p>

                        <div>
                            <p className="info">
                                Czas trwania: około 60 minut
                                <br />
                                <br />
                                Częstotliwość: regularnie, co tydzień lub co dwa
                                tygodnie.
                            </p>

                            <p>
                                Z chęcią zorganizuję wspaniałą edukacyjną
                                przygodę – napisz, aby otrzymać ofertę już dziś!
                            </p>

                            <div
                                style={{
                                    padding: "50px 0 0",
                                    textAlign: "center",
                                }}
                            >
                                <a href={mailtoLinkWarsztaty}>
                                    <StandardButton
                                        backgroundColor={COLORS.blue}
                                        color={COLORS.cream}
                                    >
                                        Spotkajmy się
                                    </StandardButton>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid-1-a">
                    <div>
                        <SectionHeader
                            color={COLORS.blue}
                            data-aos="fade-up"
                            id="laboratorium"
                        >
                            Warsztaty "Zostań Naukowcem" - Przygoda z
                            Laboratorium
                        </SectionHeader>

                        <p>
                            Wspólnie rozwijajmy ciekawość i umiejętności
                            naukowe! Na warsztatach "Zostań Naukowcem" dzieci
                            postawią hipotezy i sprawdzą je w praktyce. Kolorowa
                            zabawa i fascynujące zagadki uczynią tę naukową
                            podróż niezapomnianą.
                        </p>

                        <p>
                            Dla przedszkolaków i uczniów szkół podstawowych.
                            Warsztaty są przygotowane tak, żeby dzieci poznały
                            tajniki pracy naukowej i poznały sposób
                            wnioskowania. Mogą zostać przygotowane jako
                            pojedynczy pokaz lub kilka oddzielnych spotkań.
                        </p>

                        <p>
                            Każde spotkanie to możliwość rozmowy z naukowcem i
                            słuchania historii z prawdziwego laboratorium!
                        </p>

                        <div className="info">
                            Czas trwania 60-90 minut
                            <br />
                            <br />
                            Częstotliwość: jedno lub więcej spotkań – w
                            zależności od możliwości i zainteresowania
                        </div>

                        <p>
                            Z chęcią zorganizuję wspaniałą edukacyjną przygodę –
                            <a href={mailtoLinkWarsztaty}> napisz do mnie</a>,
                            aby otrzymać ofertę już dziś!
                        </p>
                    </div>

                    <div>
                        <img
                            src={IMAGES.Circle6Png}
                            alt=""
                            data-aos="zoom-in"
                        />
                    </div>
                </div>

                <div className="grid-a-1">
                    <div>
                        <img
                            src={IMAGES.Weekendy2Png}
                            alt=""
                            data-aos="zoom-in"
                        />
                    </div>

                    <div>
                        <div>
                            <SectionHeader
                                color={COLORS.blue}
                                data-aos="fade-up"
                                id="mikroswiat"
                            >
                                Mikroświat – Zobacz niewidzialne!
                            </SectionHeader>

                            <p>
                                Dla starszych przedszkolaków i uczniów. Jak
                                zobaczyć niewidzialne? Jak wygląda komórka ciała
                                człowieka i co jest w środku? Te warsztaty
                                gwarantują głębsze zanurzenie w mikroświat.
                            </p>

                            <p>
                                Wiele obserwacji i kilka mikroskopowych
                                eksperymentów. Przygotujemy też własne preparaty
                                mikroskopowe, które będzie można zabrać do domu.
                            </p>

                            <p>
                                Wszystko pod opieką prowadzącej - doświadczonej
                                zarówno w popularyzacji nauki wśród
                                najmłodszych, jak w pracy naukowej.
                            </p>

                            <div className="info">
                                Czas trwania: około 60 minut
                                <br />
                                <br />
                                Częstotliwość: jedno lub więcej spotkań – w
                                zależności od potrzeb grupy
                            </div>

                            <p>
                                Z chęcią przeprowadzę warsztaty także w Twoim
                                przedszkolu lub szkole –{" "}
                                <a href={mailtoLinkWarsztaty}>napisz do mnie</a>
                                , aby otrzymać ofertę już dziś!
                            </p>
                            <div
                                style={{
                                    padding: "50px 0 0",
                                    textAlign: "center",
                                }}
                            >
                                <a href={mailtoLinkWarsztaty}>
                                    <StandardButton
                                        backgroundColor={COLORS.blue}
                                        color={COLORS.cream}
                                    >
                                        Spotkajmy się
                                    </StandardButton>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid-1-a">
                    <div>
                        <SectionHeader color={COLORS.blue} data-aos="fade-up" id="uzupelnianie">
                            Wzbogacenie zajęć w przedszkolu i szkole
                        </SectionHeader>

                        <div>
                            <p>
                                Niektóre zagadnienia w przedszkolu czy szkole są
                                jeszcze ciekawsze, gdy można ich doświadczyć.
                                Obecnie scenariusze różnych eksperymentów są
                                łatwe do znalezienia. Mimo, że szybko dostępne,
                                nie zawsze sprawdzają się w praktyce.
                                Sprawdzanie ich przed spotkaniem z dziećmi i
                                zaopatrzenie się w składniki potrzebne do ich
                                wykonania bywa czasochłonne.
                            </p>

                            <p>
                                Z chęcią podzielę się moim doświadczeniem,
                                pomagając wzbogacić w eksperymenty wybrane
                                zajęcia czy lekcje. Dobiorę odpowiednie
                                doświadczenia, zagadki i zabawy ruchowe tak, aby
                                wzbogacić czas poświęcony na edukację!
                            </p>

                            <p className="info">
                                Czas trwania: 45-60 minut
                                <br />
                                <br />
                                Częstotliwość: w zależności od potrzeb grupy
                            </p>

                            <p>
                                Z chęcią wzbogacę ofertę Twojego przedszkola lub
                                szkoły –{" "}
                                <a href={mailtoLinkWarsztaty}>napisz do mnie</a>
                                , aby otrzymać ofertę już dziś!
                            </p>
                        </div>
                    </div>
                    <div>
                        <img
                            src={IMAGES.Circle1Png}
                            alt=""
                            data-aos="zoom-in"
                        />
                    </div>
                </div>
            </SubpageWrapper>
        </Styled.MainContent>
    );
};

export default ZajeciaPage;