export const MAIN_WIDTH = '1200px';
export const MAIN_NAVIGATION_WIDTH = '1800px';

export const ID = {
    gallery: "galeria",
    footer: "footer",
    contact: "kontakt",
    offer: "oferta",
    workshops: "warsztaty",
    about: "about",
    header: "header",
}

export const COLORS = {
    green: '#00cb7a',
    blue: '#2543b4',
    orange: "#ffb500",
    red: '#fd5525',
    cream: '#FFFAEF',
}

export const ROUTES = {
    kontakt: 'kontakt',
    poznajmySie: 'poznajmy-sie',
    galeria: 'galeria',
    zajęcia: 'zajecia-w-szkolach-i-przedszkolach',
    spotkaniaWeekendowe: 'spotkania-w-weekendy',
    home: '/',
}