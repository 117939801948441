import LogoJpg from './logo.jpg';
import LogoSvg from './logo.svg';
import WarsztatyJpg from './warsztaty.jpg';
import RegularneWarsztatyJpg from './regularne-warsztaty.jpg'
import PlaceholderJpg from './placeholder.jpg';
import AboutJpg from './o-mnie_1.jpg';
import AboutWorkshops from './o-zajeciach_1.jpg';
import ZostanNaukowcem from './zostan-naukowcem_1.jpg';
import Mikroswiat from './mikroswiat.jpg';
import Mikroswiat1 from './mikroswiat_1.jpg';
import KomorkaJpg from './6-573A2619.jpg';
import InstagramSvg from './instagram.svg';
import FacebookSvg from './facebook.svg';
import Karuzela1Jpg from './karuzela/karuzela-1.jpg'
import Karuzela2Jpg from './karuzela/karuzela-2.jpg'
import Karuzela3Jpg from './karuzela/karuzela-3.jpg'
import WavePng from './wave-nav.png';
import LogoLabelSvg from './logoLabel.svg';
import Circle1Png from './circles/mikroswiat.png';
import Circle2Png from './circles/ofert_spotkania_z_nauką_w_weekend.png';
import Circle3Png from './circles/ofert_urodziny.png';
import Circle4Png from './circles/ofert_uzupelnienie.png';
import Circle5Png from './circles/oferta_zagadnienia_przyr.png';
import Circle6Png from './circles/zostan_naukowcem.png';
import Weekendy1Png from './weekendy1.png';
import Weekendy2Png from './weekendy2.png';
import Weekendy3Png from './weekendy3.png';
import CircleGosia from './circles/icon-portrait.png';
import CircleBlue from './circles/blue.svg';
import CircleGreen from './circles/green.svg';
import CircleRed from './circles/red.svg';
import CircleMail from './circles/mail.svg';
import CircleLab from './circles/lab.png';
import CircleYellow from './circles/yellow.svg';
import CircleGift from './circles/gift.svg';
import CirclePoznajmySie1 from './circles/poznajmySie1.png';
import CirclePoznajmySie2 from './circles/poznajmySie2.png';


const IMAGES = {
  LogoJpg: LogoJpg,
  LogoSvg: LogoSvg,
  LogoLabelSvg: LogoLabelSvg,
  PlaceholderJpg: PlaceholderJpg,
  WarsztatyJpg: WarsztatyJpg,
  RegularneWarsztatyJpg: RegularneWarsztatyJpg,
  AboutJpg: AboutJpg,
  AboutWorkshops: AboutWorkshops,
  ZostanNaukowcem: ZostanNaukowcem,
  Mikroswiat: Mikroswiat,
  Mikroswiat1: Mikroswiat1,
  KomorkaJpg: KomorkaJpg,
  InstagramSvg: InstagramSvg,
  FacebookSvg: FacebookSvg,
  Karuzela1Jpg: Karuzela1Jpg,
  Karuzela2Jpg: Karuzela2Jpg,
  Karuzela3Jpg: Karuzela3Jpg,
  WavePng: WavePng,
  Circle1Png: Circle1Png,
  Circle2Png: Circle2Png,
  Circle3Png: Circle3Png,
  Circle4Png: Circle4Png,
  Circle5Png: Circle5Png,
  Circle6Png: Circle6Png,
  Weekendy1Png: Weekendy1Png,
  Weekendy2Png: Weekendy2Png,
  Weekendy3Png: Weekendy3Png,
  CircleGosia: CircleGosia,
  CircleBlue: CircleBlue,
  CircleGreen: CircleGreen,
  CircleRed: CircleRed,
  CircleMail: CircleMail,
  CircleLab: CircleLab,
  CircleYellow: CircleYellow,
  CircleGift: CircleGift,
  CirclePoznajmySie1: CirclePoznajmySie1,
  CirclePoznajmySie2: CirclePoznajmySie2,
};

export default IMAGES;