// @ts-nocheck
import { COLORS } from "config/layout";
import styled from "styled-components";

const PlaceholderSlide = styled.div`
    height: calc(100vh - 200px);
    min-height: 600px;
    background-size: cover;
    background-position: center center;
    background-image: url(${({ image }) => image && image});
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
`;

const Text = styled.h3`
    text-shadow: 2px 2px 4px rgba(0, 0, 0, .9);
    font-size: 32px;
    text-align: center;
    color: white;
    text-transform: uppercase;
`;

const WaveBackground = styled.div`
    width: 100%;
    position: relative;
    z-index: 1;
    padding: 50px 0 50px;
    background-color: ${COLORS.blue};
    color: ${COLORS.cream};
    text-align: center;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, .9);

    .title {
        font-size: 45px;
        margin: 5px 0 10px;
        padding: 0 15px;

        @media(max-width: 1000px) {
            font-size: 30px;
        }
    }

    .text {
        font-size: 30px;
        padding: 0 15px;

        @media(max-width: 1000px) {
            font-size: 20px;
        }
    }
    
    img {
        position: absolute;
        display: block;
        width: 100%;
        bottom: calc(100% - 15px);
        transform: rotateX(180deg);

        @media(max-width: 1000px) {
            bottom: 100%;
        }
    }
`;

export default { PlaceholderSlide, Text, WaveBackground };
