import { useEffect, useState } from "react";

const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(false);

    const checkScreenWidth = () => {
        const screenWidth = window.innerWidth;
        setIsMobile(screenWidth <= 1000);
    };

    useEffect(() => {
        // Initial check on hook usage
        checkScreenWidth();

        // Add event listener for screen resize
        window.addEventListener("resize", checkScreenWidth);

        // Remove event listener on hook cleanup
        return () => {
            window.removeEventListener("resize", checkScreenWidth);
        };
    }, []); // Empty dependency array ensures useEffect runs only once on mount

    return isMobile;
};

export default useIsMobile;
