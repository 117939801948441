import React from 'react'
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

import { COLORS } from 'config/layout'

import { SectionHeader } from 'sections/Sections.style'


const importAll = (r) => {
    let images = {};

    r.keys().map((item) => {
        images[item.replace("./", "")] = r(item);
    });

    return images;
};

// @ts-ignore
// eslint-disable-next-line no-undef
const images = importAll(require.context('../../../public/galeria', false, /\.(png|jpe?g|svg)$/));

const galleryImages = Object.keys(images).map((image) => {
    return {
        original: `/galeria/${image}`,
        thumbnail: `/galeria/${image}`,
        loading: "lazy",
        thumbnailLoading: "lazy",
    };
});


const MainGallery = () => (
    <div>
        <SectionHeader color={COLORS.green} data-aos="fade-up">Galeria</SectionHeader>

        <div style={{ padding: "30px 0 50px" }}>
            {/* @ts-ignore */}
            <ImageGallery items={galleryImages} />

            <div style={{ clear: "both" }}></div>
        </div>
    </div>
);

export default MainGallery;