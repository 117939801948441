import { COLORS } from "config/layout";
import styled from "styled-components";

const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;

    label {
        font-size: 12px;
    }

    input, select, textarea {
        min-width: 300px;
        padding: 15px;
        border-radius: 0;
        background-color: white;
        outline: none;
        border: 1px solid ${COLORS.green};
        border-radius: 10px;
    }

    select {
        cursor: pointer;
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 1px;
        text-overflow: '';
    }

    option {
        cursor: pointer;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance:textfield; /* Firefox */
    }

    button {
        width: 300px;
        background: ${COLORS.green};
        color: white;
    }

    textarea {
        resize: none;
    }
`;

export default { Form };
