import React from 'react'

import Styled from '../MainPage/MainPage.style';
import GallerySection from "sections/Gallery/Gallery";
import { SubpageWrapper } from 'sections/Sections.style';

const Gallery = () => {
    return (
        <Styled.MainContent id="home">
            <SubpageWrapper>
                <GallerySection />
            </SubpageWrapper>
        </Styled.MainContent>
    );
};

export default Gallery;