import AOS from "aos";
import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { ROUTES } from "config/layout";
import useIsMobile from "hooks/UseMobile";
import ScrollingHelper from "hooks/ScrollingHelper";

import Header from "sections/Header/Header";
import Footer from "sections/Footer/Footer";
import Gallery from "pages/Gallery/Gallery";
import MainPage from "pages/MainPage/MainPage";
import ZajeciaPage from "pages/ZajeciaPage/ZajeciaPage";
import ContactPage from "pages/ContactPage/ContactPage";
import PoznajmySiePage from "pages/PoznajmySiePage/PoznajmySiePage";
import WeekendMeetingPage from "pages/WeekendMeetingPage/WeekendMeetingPage";
import HeaderMobile from "sections/HeaderMobile/HeaderMobile";


function App() {

    useEffect(() => {
        AOS.init({
        once: true
        });
    }, []);

    const isMobile = useIsMobile();

    const header = isMobile
        ? <HeaderMobile />
        : <Header />;

    return (
        <>
            <Router>
                <ScrollingHelper />
                {header}

                <Routes>
                    <Route path={`/${ROUTES.galeria}`} element={<Gallery />} />
                    <Route path={`/${ROUTES.zajęcia}`} element={<ZajeciaPage />} />
                    <Route path={`/${ROUTES.kontakt}`} element={<ContactPage />} />
                    <Route path={`/${ROUTES.poznajmySie}`} element={<PoznajmySiePage />} />
                    <Route path={`/${ROUTES.spotkaniaWeekendowe}`} element={<WeekendMeetingPage />} />

                    <Route path={ROUTES.home} element={<MainPage />} />
                    <Route path="*" element={<MainPage />} />
                </Routes>

                <Footer />
            </Router>
        </>
    );
}

export default App;
