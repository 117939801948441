// @ts-nocheck
import { COLORS, MAIN_WIDTH } from "config/layout";
import styled from "styled-components";

export const StandardSection = styled.section`
    max-width: ${MAIN_WIDTH};
    margin: 0 auto;
    padding: 80px 0 40px;
    position: relative;
    ${({ padding }) => padding && `padding: ${padding}`};
`;

export const SubpageWrapper = styled.div`
    max-width: ${MAIN_WIDTH};
    margin: 0 auto;
    padding: 150px 0 80px;
    position: relative;
    min-height: calc(100vh-214px);

    @media(max-width: 1000px) {
        padding: 80px 25px 50px;
        min-height: calc(100vh-110px);
    }
`;

export const TwoColumnsContent = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 30px 0 50px;
    min-height: 35vh;
    align-items: center;
    gap: 30px;

    @media(max-width: 1000px) {
        display: flex;
        flex-direction: column;
    }

    > div {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        p {
            text-align: justify;
        }
    }
`;

export const SectionHeader = styled.h2`
    color: ${({ color }) => color};
    text-align: center;
    text-transform: uppercase;
    font-size: 35px;
    font-weight: 300;
    font-family: 'Protest Riot', sans-serif;

    @media(max-width: 1000px) {
        font-size: 30px;
    }
`;

 export const StandardButton = styled.button`
    border-radius: 30px;
    padding: 15px 20px;
    border: none;
    color: ${({ color }) => color ? color : COLORS.blue};
    background: ${({ backgroundColor }) => backgroundColor ? backgroundColor : `linear-gradient(160deg, rgb(255, 181, 0) 0%, rgb(255, 144, 47) 100%)`};
    box-shadow: 1px 1px 2px rgba(0, 0, 0, .4);
    font-weight: 500;
    font-size: 20px;
    position: relative;
    z-index: 1;
    cursor: pointer;
    min-width: 200px;
    text-decoration: none;
    font-family: "Comfortaa", sans-serif;

    ${({ centered }) => centered && `
        display: block;
        margin: 35px auto 0;
    `}
`;
