// @ts-nocheck
import React from 'react'
import { Link } from "react-router-dom";

import IMAGES from 'assets/images';
import { COLORS, ROUTES } from 'config/layout';
import { SectionHeader, StandardButton } from 'sections/Sections.style';

import Styled from './ThreeColumns.styled';


const { orange, blue } = COLORS;

const ThreeColumnsSection = () => {
    return (
        <Styled.Section>
            <Styled.WaveDecor src={IMAGES.WavePng} alt="" />

            <Styled.ContentWrapper>
                <SectionHeader color={COLORS.blue} data-aos="fade-up">
                    Zajęcia przyrodnicze i mikroskopowe dla dzieci
                </SectionHeader>

                <div style={{ textAlign: 'center', paddingTop: '50px' }}>
                    <img src={IMAGES.CircleLab} alt="" width={200} data-aos="zoom-in" />
                </div>

                <Styled.TextWrapper>
                    <div data-aos="fade-up">
                        <div className="text">
                            Każde zajęcia przygotowuję tak, aby były jak
                            najlepiej odpowiadały potrzebą danej grupy. Za
                            każdym razem poruszam inną tematykę, projektuję
                            doświadczenia, przygotowuję obserwacje mikroskopowe
                        </div>
                        <Link to={ROUTES.spotkaniaWeekendowe}>
                            <StandardButton
                                color={blue}
                                backgroundColor={orange}
                                centered
                            >
                                Dla rodziców
                            </StandardButton>
                        </Link>
                    </div>
                    <div data-aos="fade-up">
                        <div className="text">
                            Każde warsztaty to doskonała zabawa, zagadki, ale
                            też czas na uspakajający ruch.
                        </div>
                        <Link to={ROUTES.zajęcia}>
                            <StandardButton
                                color={blue}
                                backgroundColor={orange}
                                centered
                            >
                                Dla przedszkoli
                            </StandardButton>
                        </Link>
                    </div>
                    <div data-aos="fade-up">
                        <div className="text">
                            Uczestnicy otrzymają ode mnie wszystko co potrzebne
                            podczas spotkania, a także materiały pomagające
                            przedstawić eksperymenty po powrocie do domu.
                        </div>
                        <Link to={ROUTES.zajęcia}>
                            <StandardButton
                                color={blue}
                                backgroundColor={orange}
                                centered
                            >
                                Dla szkół
                            </StandardButton>
                        </Link>
                    </div>
                </Styled.TextWrapper>
            </Styled.ContentWrapper>
        </Styled.Section>
    );
}

export default ThreeColumnsSection;