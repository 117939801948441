import styled from "styled-components";

import { COLORS, MAIN_WIDTH } from "config/layout";


const ContentWrapper = styled.div`
    padding: 70px 15px;
    max-width: ${MAIN_WIDTH};
    margin: 0 auto;
`;

const Title = styled.h3`
    text-align: center;
    margin: 0;
    color: ${COLORS.cream};
    font-size: 30px;
`;

const TextWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 50px;
    color: #FFFAEF;
    font-size: 25px;
    text-align: justify;

    @media(max-width: 1000px) {
        font-size: 20px;
    }
`;

const WaveDecor = styled.img`
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 100%;
    display: block;
    transform: rotateX(180deg);
`;

const Section = styled.section`
    padding: 0 0 75px;
    position: relative;
    background-color: ${COLORS.blue};
`;

const TextContentWrapper = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 50px;
    align-items: center;
    padding-top: 50px;

    @media(max-width: 1000px) {
        grid-template-columns: 1fr;
    }
`;

export default {
    Title,
    Section,
    WaveDecor,
    TextWrapper,
    ContentWrapper,
    TextContentWrapper,
};
