import styled from "styled-components";

import { COLORS, MAIN_NAVIGATION_WIDTH } from "config/layout";

const Header = styled.header`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${COLORS.blue};
    color: ${COLORS.cream};
    z-index: 5;
`;

const HeaderContentWrapper = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 110px;
    max-width: ${MAIN_NAVIGATION_WIDTH};
    width: 100%;
    padding: 10px 35px 0;
    position: relative;
    z-index: 2;
    margin: 0 auto;

    @media(max-width: 1350px) {
        gap: 40px;
    }
`;

const Menu = styled.nav`
    display: flex;
    align-items: center;
    justify-content: end;

    ul {
        display: flex;
        justify-content: space-between;
        list-style: none;
        margin: 0;
        padding-bottom: 15px;
        font-family: 'Protest Riot', sans-serif;
        font-size: 22px;
        text-transform: uppercase;
        width: 100%;
        max-width: 1380px;
        gap: 20px;

        li {
            
            a {
                text-decoration: none;
                color: ${COLORS.cream};
                transition: 0.3s ease;

                &:hover {
                    color: ${COLORS.orange};
                }
            }
        }
    }
`;

const Logo = styled.img`
    position: absolute;
    height: 150px;
`;

const LogoWrapper = styled.div`
    transform: translate(-60px, 15px);
    height: 110px;
    width: 150px;

    a {
        display: block;
    }

    @media(max-width: 1870px) {
        transform: translate(0, 15px);
    }

    @media(max-width: 1350px) {
        transform: translate(-15px, 0px)
    }
`;

const BackgroundWaveWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`;

const WaveBackground = styled.div`
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: calc(100% - 15px);
    z-index: 1;

    img {
        display: block;
        width: 100%;
    }
`;

export default {
    Logo,
    Menu,
    Header,
    LogoWrapper,
    WaveBackground,
    HeaderContentWrapper,
    BackgroundWaveWrapper,
};
