import React from 'react'

import Styled from './MainPage.style';
import MainCarousel from 'components/MainCarousel/MainCarousel';
import ThreeColumnsSection from 'sections/ThreeColumns/ThreeColumns';
import PoznajmySection from 'sections/PoznajmySection/PoznajmySection';
import FiveCirclesSection from 'sections/FiveCirclesSection/FiveCirclesSection';


const MainPage = () => {

    return (
        <Styled.MainContent id="home">
            <MainCarousel />
            <ThreeColumnsSection />
            <FiveCirclesSection />
            <PoznajmySection />
        </Styled.MainContent>
    );
};

export default MainPage;