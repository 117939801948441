export const EMAIL_ADDRESS = 'gosiamicroscope@gmail.com';

export const FACEBOOK_URL = 'https://www.facebook.com/profile.php?id=61556497855433';

export const INSTAGRAM_URL = 'https://www.instagram.com/microscope_warsztaty/';

export const EMPTY_MAIL_TO_LINK = `
    mailto:${EMAIL_ADDRESS}
    ?subject=${encodeURIComponent("Zorganizujmy warsztaty")}
    &body=${encodeURIComponent(`
        Typ zajęć: \n
        Placówka: \n
        Dzielnica/Adres: \n
        Ilość dzieci: \n
        Wiek dzieci: \n
        Dodatkowe uwagi: `
)}`;