// @ts-nocheck
import React from 'react'

import IMAGES from 'assets/images';
import { COLORS } from 'config/layout';
import { SectionHeader } from 'sections/Sections.style';

import Styled from './PoznajmySection.styled';


const PoznajmySection = () => {
    return (
        <Styled.Section>
            <Styled.WaveDecor src={IMAGES.WavePng} alt="" />

            <Styled.ContentWrapper>
                <SectionHeader color={COLORS.cream}>
                    Poznajmy się! Dlaczego warto zaprosić mnie do wspólnych
                    odkryć?
                </SectionHeader>

                <Styled.TextContentWrapper>
                    <div>
                        <img
                            src={IMAGES.Circle6Png}
                            alt="Grupka dzieci w laboratorium"
                            width={260}
                            style={{ display: 'block', margin: '0 auto' }}
                        />
                    </div>

                    <Styled.TextWrapper
                        data-aos="fade-up"
                        data-aos-delay="300"
                        data-aos-anchor-placement="bottom-bottom"
                    >
                        <div>
                            Spotkanie ze mną to czas spędzony z naukowcem –
                            jestem doktorem biochemii. Od lat popularyzuję naukę
                            wśród dzieci i młodzieży. Każde warsztaty prowadzę
                            osobiście!
                        </div>
                        <div>
                            Spotkania z pasją – moja historia zaczęła się dzięki
                            wspaniałym osobom, które spotkałam na swojej drodze
                            – teraz chcę dzielić się moją pasją z dziećmi!
                        </div>
                        <div>
                            Spotkania z zaangażowaniem – każde spotkanie to inne
                            odkrycia. Zajęcia są pełne kolorów, ale też
                            uspakajanego ruchu. Zawsze zaprojektowane tak, aby
                            jak najlepiej odpowiadały potrzebom konkretnej grupy
                            i bezpieczne!
                        </div>
                    </Styled.TextWrapper>
                </Styled.TextContentWrapper>
            </Styled.ContentWrapper>
        </Styled.Section>
    );
}

export default PoznajmySection;