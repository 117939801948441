import { MAIN_WIDTH } from "config/layout";
import styled from "styled-components";

const ContentWrapper = styled.div`
    max-width: ${MAIN_WIDTH};
    margin: 0 auto;
`;

const List = styled.ul`
    list-style: decimal;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-left: 15px;
    font-size: 18px;
    line-height: 1.4;
`;

const Image = styled.img`
    max-width: 200px;
    display: block;

    @media(max-width: 1000px) {
        margin: 0 auto;
    }
`;

export default { ContentWrapper, List, Image };
