// @ts-nocheck
import React from 'react'

import IMAGES from 'assets/images';
import { COLORS } from 'config/layout';
import { EMAIL_ADDRESS, FACEBOOK_URL } from 'config/data';

import { SectionHeader, StandardButton, SubpageWrapper } from "sections/Sections.style";
import Styled from '../MainPage/MainPage.style';


const WeekendMeetingPage = () => {

    const mailtoLinkWarsztaty = `
        mailto:${EMAIL_ADDRESS}?subject=${encodeURIComponent(
            "Warsztaty Microscope"
        )}`;

    const mailtoLinkUrodziny = `
        mailto:${EMAIL_ADDRESS}?subject=${encodeURIComponent(
            "Urodziny Microscope"
        )}`;

    return (
        <Styled.MainContent>
            <SubpageWrapper>
                <div>
                    <div className="grid-a-1">
                        <div>
                            <img src={IMAGES.Weekendy1Png} alt="" />
                        </div>

                        <div>
                            <SectionHeader
                                color={COLORS.blue}
                                data-aos="fade-up"
                            >
                                Warsztaty
                            </SectionHeader>

                            <p>
                                Twoje dziecko jest pełne energii, czy potrzebuje
                                chwili, aby poczuć się bezpiecznie? Chcesz dać
                                mu możliwość rozwoju i doskonałe zabawy weekend,
                                ale nie wiesz, jak to zrobić?
                            </p>

                            <p>
                                Zapraszam do odkrywania fascynującego świata
                                nauki, gdzie każdy może być małym odkrywcą
                                mikroświata! Rodzice mogą zawsze zostać na
                                zajęciach lub pozostawić dziecko pod moją
                                opieką.{" "}
                            </p>

                            <p>
                                Każde zajęcia prowadzę osobiście. Regularne
                                spotkania z poznaną już osobą to gwarancja, że
                                nawet wrażliwe dziecko szybko poczuje się
                                bezpiecznie. To też pewność, że Twoje dziecko
                                będzie pod opieką osoby odpowiedzialnej.
                            </p>
                        </div>
                    </div>

                    <div className="grid-1-a">
                        <div>
                            <p>
                                Warsztaty odbywają się w różnych lokalizacjach w
                                Warszawie i przybierają różne formy.
                            </p>

                            <p>
                                Aby dowiedzieć się, gdzie możesz mnie spotkać w
                                najbliższym czasie
                                <a
                                    href={FACEBOOK_URL}
                                    style={{ color: COLORS.green }}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {" "}
                                    odwiedź mój facebook{" "}
                                </a>
                                lub napisz klikając w poniższy link!
                                <div
                                    style={{
                                        padding: "50px 0 0",
                                        textAlign: "center",
                                    }}
                                >
                                    <a href={mailtoLinkWarsztaty}>
                                        <StandardButton
                                            backgroundColor={COLORS.blue}
                                            color={COLORS.cream}
                                        >
                                            Spotkajmy się
                                        </StandardButton>
                                    </a>
                                </div>
                            </p>
                        </div>

                        <div>
                            <img src={IMAGES.Weekendy2Png} alt="" />
                        </div>
                    </div>
                </div>

                <div>
                    <div className="grid-a-1">
                        <div>
                            <img src={IMAGES.Weekendy3Png} alt="" />
                        </div>

                        <div>
                            <SectionHeader
                                color={COLORS.blue}
                                data-aos="fade-up"
                                id="urodziny"
                            >
                                Urodziny
                            </SectionHeader>

                            <p>
                                Mały naukowiec obchodzi niedługo urodziny i
                                marzy o wyjątkowej imprezie? To doskonała
                                okazja, żeby sprawić mu wspaniały prezent
                                organizując kolorowe eksperymenty. Ze mną nie
                                musisz ich wymyślać samodzielnie, sprawdzać czy
                                zadziałają i kupować wielu odczynników -
                                wystarczy, że zapewnisz salę i zaprosisz gości,
                                a ja zajmę się organizacją doskonałej zabawy!
                            </p>

                            <div>
                                <div
                                    style={{
                                        padding: "50px 0 0",
                                        textAlign: "center",
                                    }}
                                >
                                    <a href={mailtoLinkUrodziny}>
                                        <StandardButton
                                            backgroundColor={COLORS.green}
                                            color={COLORS.cream}
                                        >
                                            Robimy urodziny z Microscope!
                                        </StandardButton>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </SubpageWrapper>
        </Styled.MainContent>
    );
};

export default WeekendMeetingPage;