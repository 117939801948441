import React from 'react'

import { COLORS } from 'config/layout';
import { SectionHeader, SubpageWrapper, TwoColumnsContent } from 'sections/Sections.style';

import UserRequestForm from "./UserRequestForm/UserRequestForm";
import Styled from './ContactSection.style';
import IMAGES from 'assets/images';


const ContactSection = () => {
    return (
        <SubpageWrapper>
            <Styled.ContentWrapper>
                <SectionHeader color={COLORS.red} data-aos="fade-up">Kontakt</SectionHeader>

                <TwoColumnsContent>
                    <div>
                        <div>
                            <Styled.Image src={IMAGES.CircleMail} alt="Znaczek listu z kolorową tęczą nad nim" />
                        </div>

                        <p>Na każde warsztaty zapewniam:</p>

                        <Styled.List>
                            <li>
                                opiekę doświadczonej animatorki umożliwiającą
                                samodzielną i bezpieczną realizację
                                autorskiego, fascynującego programu
                            </li>
                            <li>
                                zapewnienie materiałów potrzebnych do
                                bezpiecznej pracy (fartuszki, gogle, tacki)
                            </li>
                            <li>
                                możliwość obserwacji mikroskopowych - posiadam
                                wysokiej jakości mikroskopy marki Levenhuk
                            </li>
                            <li>
                                wszystkie materiały potrzebne do eksperymentów
                                (tacki, fartuszki, bezpieczne odczynniki,
                                profesjonalne naczynia, wydruki itp)
                            </li>
                            <li>
                                profesjonalne i bezpieczne naczynia potrzebne do
                                doświadczeń
                            </li>
                        </Styled.List>
                    </div>
                    <div>
                        <p>
                            Ceny zależą od rodzaju zajęć - dlatego proszę o
                            wypełnienie formularza lub kontakt:
                        </p>
                        <div>
                            <UserRequestForm />
                        </div>
                    </div>
                </TwoColumnsContent>
            </Styled.ContentWrapper>
        </SubpageWrapper>
    );
};

export default ContactSection;