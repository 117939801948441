import React from 'react'

import ContactSection from "sections/ContactSection/ContactSection";

import Styled from '../MainPage/MainPage.style';

const ContactPage = () => {

    return (
        <Styled.MainContent id="home">
            <ContactSection />
        </Styled.MainContent>
    );
};

export default ContactPage