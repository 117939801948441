import styled from "styled-components";

import IMAGES from "assets/images";


const MainContent = styled.main`
    padding-top: 80px;
`;

const MainImage = styled.div`
    width: 100%;
    min-height: 200px;
    max-height: 800px;
    height: calc(60vh + 40px);
    background-image: url(${({ image }) => image ? image : IMAGES.WarsztatyJpg});
    background-size: cover;
    background-position: center;
`;

export default { MainContent, MainImage };
