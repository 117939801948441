// @ts-nocheck
import React from 'react'
import { Link } from "react-router-dom";
import "@splidejs/react-splide/css";
import { Splide, SplideSlide } from "@splidejs/react-splide";

import IMAGES from 'assets/images';
import { COLORS, ROUTES } from 'config/layout';

import { StandardButton } from 'sections/Sections.style';
import Styled from './MainCarousel.style';


const options = {
    type: 'loop',
    perPage: 1,
    loop: true,
    pagination: false,
    autoplay: true,
    interval: 3000,
    speed: 750,
    pauseOnHover: false,
};

const buttonStyle = { margin: '50px 0 -60px' };


const MainCarousel = () => {
  return (
      <div>
          <Splide aria-label="My Favorite Images" options={options}>
              <SplideSlide>
                  <Styled.PlaceholderSlide image={IMAGES.Karuzela1Jpg}>
                      <Styled.Text>
                          Warsztaty zostań naukowcem
                          <div style={buttonStyle}>
                              <Link to={ROUTES.zajęcia}>
                                  <StandardButton>O warsztatach</StandardButton>
                              </Link>
                          </div>
                      </Styled.Text>
                  </Styled.PlaceholderSlide>
              </SplideSlide>
              <SplideSlide>
                  <Styled.PlaceholderSlide image={IMAGES.Karuzela2Jpg}>
                      <Styled.Text>
                          Spotkania z niewidzialnym światem
                          <div style={buttonStyle}>
                              <Link to={ROUTES.zajęcia}>
                                  <StandardButton>O zajęciach</StandardButton>
                              </Link>
                          </div>
                      </Styled.Text>
                  </Styled.PlaceholderSlide>
              </SplideSlide>
              <SplideSlide>
                  <Styled.PlaceholderSlide image={IMAGES.Karuzela3Jpg}>
                      <Styled.Text>
                          Warsztaty weekendowe i urodzinowe
                          <div style={buttonStyle}>
                              <Link to={ROUTES.spotkaniaWeekendowe}>
                                  <StandardButton>Dowiedz się więcej</StandardButton>
                              </Link>
                          </div>
                      </Styled.Text>
                  </Styled.PlaceholderSlide>
              </SplideSlide>
          </Splide>

          <Styled.WaveBackground>
              <img src={IMAGES.WavePng} alt="" />

              <h3 className="title">Naukowa zabawa w wolnym czasie?</h3>
              <div className="text">Spotkajmy się w weekend!</div>

              <div style={{ marginTop: "30px" }}>
                  <Link to={ROUTES.spotkaniaWeekendowe}>
                      <StandardButton
                          color={COLORS.orange}
                          backgroundColor={COLORS.cream}
                      >
                          Dowiedz się więcej
                      </StandardButton>
                  </Link>
              </div>
          </Styled.WaveBackground>
      </div>
  );
}

export default MainCarousel