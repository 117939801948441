import { COLORS, MAIN_WIDTH } from "config/layout";
import styled from "styled-components";

const ContentWrapper = styled.div`
    padding: 70px 15px;
    max-width: ${MAIN_WIDTH};
    margin: 0 auto;

    @media(max-width: 1000px) {
        padding: 0 15px;
    }
`;

const Title = styled.h3`
    text-align: center;
    margin: 0;
    color: ${COLORS.green};
    font-size: 30px;
`;

const TextWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 65px;
    color: ${COLORS.blue};
    font-size: 20px;
    text-align: justify;
    padding-top: 50px;

    @media(max-width: 1000px) {
        grid-template-columns: 1fr;
        max-width: 500px;
        margin: 0 auto;
    }

    .text {
        min-height: 185px;

        @media(max-width: 1000px) {
            min-height: unset;
        }
    }
`;

const WaveDecor = styled.img`
    position: absolute;
    left: 0;
    width: 100%;
    top: 0;
    display: block;
    transform: rotateY(180deg);
`;

const Section = styled.section`
    padding: 80px 0;
    position: relative;
`;

export default { ContentWrapper, Title, TextWrapper, WaveDecor, Section };
