import React from 'react'
import { Link } from 'react-router-dom';

import IMAGES from 'assets/images';
import { ID, ROUTES } from 'config/layout';

import Styled from './Header.style';


const Header = () => {
	return (
        <Styled.Header id={ID.header}>
            <Styled.BackgroundWaveWrapper>
                <Styled.HeaderContentWrapper>
                    <Styled.LogoWrapper>
                        <Link to={ROUTES.home}>
                            <Styled.Logo
                                src={IMAGES.LogoLabelSvg}
                                alt="Logo Microscope"
                            />
                        </Link>
                    </Styled.LogoWrapper>

                    <Styled.Menu>
                        <ul>
                            <li>
                                <Link to={ROUTES.spotkaniaWeekendowe}>
                                    Spotkania w weekendy
                                </Link>
                            </li>
                            <li>
                                <Link to={ROUTES.zajęcia}>
                                    Zajęcia w szkołach i przedszkolach
                                </Link>
                            </li>
                            <li>
                                <Link to={ROUTES.poznajmySie}>
                                    Poznajmy się
                                </Link>
                            </li>
                            <li>
                                <Link to={ROUTES.galeria}>Galeria</Link>
                            </li>
                            <li>
                                <Link to={ROUTES.kontakt}>
                                    Kontakt
                                </Link>
                            </li>
                        </ul>
                    </Styled.Menu>
                </Styled.HeaderContentWrapper>
                <Styled.WaveBackground>
                    <img src={IMAGES.WavePng} alt="" />
                </Styled.WaveBackground>
            </Styled.BackgroundWaveWrapper>
        </Styled.Header>
    );
}

export default Header