import React, { useState } from "react";

import { EMAIL_ADDRESS } from "config/data";

import Styled from './UserRequestForm.style';
import { StandardButton } from "sections/Sections.style";


const UserRequestForm = () => {
    const [formData, setFormData] = useState({
        selectedType: "",
        institution: "",
        district: "",
        numberOfChildren: "",
        ageOfChildren: "",
        additionalComments: "",
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const mailtoLink = `mailto:${EMAIL_ADDRESS}?subject=${encodeURIComponent(
            "Nowe zgłoszenie"
        )}&body=${encodeURIComponent(
            `Typ zajęć: ${formData.selectedType}\n
            Placówka: ${formData.institution}\n
            Dzielnica/Adres: ${formData.district}\n
            Ilość dzieci: ${formData.numberOfChildren}\n
            Wiek dzieci: ${formData.ageOfChildren}\n
            Dodatkowe uwagi: ${formData.additionalComments}`
        )}`;

        window.location.href = mailtoLink;
    };

    return (
        <Styled.Form onSubmit={handleSubmit}>
            <div>
                <label>
                    Wybierz typ zajęć:
                    <div>
                        <select
                            name="selectedType"
                            value={formData.selectedType}
                            onChange={handleChange}
                            required
                        >
                            <option value="" disabled selected>
                                Wybierz typ zajęć
                            </option>
                            <option value="Warsztaty weekendowe">
                                Warsztaty weekendowe
                            </option>
                            <option value="Urodziny">Urodziny</option>
                            <option value="Regularne warsztaty w przedszkolu/szkole">
                                Regularne warsztaty w przedszkolu/szkole
                            </option>
                            <option value="Warsztaty Zostań naukowcem">
                                Warsztaty Zostań naukowcem
                            </option>
                            <option value="Mikroświat">Mikroświat</option>
                            <option value="Inna forma współpracy">
                                Inna forma współpracy
                            </option>
                        </select>
                    </div>
                </label>
            </div>

            <div>
                <label>
                    Placówka:
                    <div>
                        <input
                            type="text"
                            name="institution"
                            value={formData.institution}
                            onChange={handleChange}
                            required
                        />
                    </div>
                </label>
            </div>

            <div>
                <label>
                    Dzielnica/Adres:
                    <div>
                        <input
                            type="text"
                            name="district"
                            value={formData.district}
                            onChange={handleChange}
                            required
                        />
                    </div>
                </label>
            </div>

            <div>
                <label>
                    Ilość dzieci:
                    <div>
                        <input
                            type="number"
                            name="numberOfChildren"
                            value={formData.numberOfChildren}
                            onChange={handleChange}
                            required
                            min={2}
                        />
                    </div>
                </label>
            </div>

            <div>
                <label>
                    Wiek dzieci:
                    <div>
                        <input
                            type="number"
                            name="ageOfChildren"
                            value={formData.ageOfChildren}
                            onChange={handleChange}
                            required
                        />
                    </div>
                </label>
            </div>

            <div>
                <label>
                    Dodatkowe uwagi:
                    <div>
                        <textarea
                            name="additionalComments"
                            value={formData.additionalComments}
                            onChange={handleChange}
                        ></textarea>
                    </div>
                </label>
            </div>

            <StandardButton type="submit">Wyślij</StandardButton>
        </Styled.Form>
    );
};

export default UserRequestForm;
