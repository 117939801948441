import styled from "styled-components";

const Footer = styled.footer`
    background: #0e2375;
    color: white;
    display: flex;
    padding: 50px;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;

    @media(max-width: 1000px) {
        padding: 25px;
        
        p {
            font-size: 14px;
        }
    }

    a {
        text-decoration: none;
    }
`;

const InstaImage = styled.img`
    margin-left: 30px;
    width: 40px;

    @media(max-width: 1000px) {
        margin-left: 15px;
    }
`;

export default { Footer, InstaImage };