/* eslint-disable react/no-unescaped-entities */
// @ts-nocheck
import React from 'react'
import { Link } from "react-router-dom";

import IMAGES from 'assets/images';
import { COLORS, ROUTES } from "config/layout";

import Styled from './FiveCirclesSection.styled';


const FiveCirclesSection = () => {
    return (
        <Styled.Section style={{ backgroundColor: COLORS.orange }}>
            <Styled.ContentWrapper>
                <Styled.UpperContent>
                    <div>
                        <div
                            className="box"
                            data-aos="zoom-in"
                            data-aos-anchor-placement="center-bottom"
                        >
                            <Link to={`${ROUTES.zajęcia}#przyrodnicze`}>
                                <img src={IMAGES.Circle1Png} alt="Kolby stożkowe z kolorowymi płynami w środku" />

                                <div className="text">
                                    Regularne Warsztaty - podróż przez
                                    zagadnienia przyrodnicze.
                                </div>
                            </Link>
                        </div>
                    </div>

                    <div>
                        <div
                            className="box"
                            data-aos="zoom-in"
                            data-aos-delay="300"
                            data-aos-anchor-placement="center-bottom"
                        >
                            <Link to={`${ROUTES.zajęcia}#laboratorium`}>
                                <img src={IMAGES.Circle2Png} alt="Grupka dzieci poznających świat z mikroskopem" />

                                <div className="text">
                                    "Zostań naukowcem" - Przygoda w
                                    laboratorium.
                                </div>
                            </Link>
                        </div>
                    </div>
                </Styled.UpperContent>
                <Styled.LowerContent>
                    <div>
                        <div
                            className="box"
                            data-aos="zoom-in"
                            data-aos-delay="300"
                            data-aos-anchor-placement="center-bottom"
                        >
                            <Link to={`${ROUTES.zajęcia}#mikroswiat`}>
                                <img src={IMAGES.Circle3Png} alt="Dzieci biegnące przez pole z kolorowymi balonami" />

                                <div className="text">
                                    Mikroświat - spotkanie z niewidzialnym
                                </div>
                            </Link>
                        </div>
                    </div>

                    <div>
                        <div
                            className="box"
                            data-aos="zoom-in"
                            data-aos-delay="600"
                            data-aos-anchor-placement="center-bottom"
                        >
                            <Link to={`${ROUTES.zajęcia}#uzupelnianie`}>
                                <img src={IMAGES.Circle4Png} alt="Dzieci rysujące kolorowe literki" />

                                <div className="text">Uzupełnienie zajęć</div>
                            </Link>
                        </div>
                    </div>

                    <div>
                        <div
                            className="box"
                            data-aos="zoom-in"
                            data-aos-delay="900"
                            data-aos-anchor-placement="center-bottom"
                        >
                            <Link to={`${ROUTES.spotkaniaWeekendowe}#urodziny`}>
                                <img src={IMAGES.Circle5Png} alt="Dziewczynka ciesząca się eksperymentami w laboratorium" />

                                <div className="text">Warsztaty urodzinowe</div>
                            </Link>
                        </div>
                    </div>
                </Styled.LowerContent>
            </Styled.ContentWrapper>
        </Styled.Section>
    );
}

export default FiveCirclesSection;