import { COLORS, MAIN_WIDTH } from "config/layout";
import styled from "styled-components";

const ContentWrapper = styled.div`
    padding: 70px 15px;
    max-width: ${MAIN_WIDTH};
    margin: 0 auto;

    .box {
        position: relative;
        border-radius: 50%;

        &:hover {
            img {
                filter: brightness(0.6);
            }
        }

        img {
            cursor: pointer;
            transition: 0.3s ease;
            border-radius: 50%;
            display: block;
            margin: 0 auto;
        }
    }

    .text {
        position: absolute;
        cursor: pointer;
        width: 80%;
        top: 50%;
        transform: translate(-50%, -50%);
        left: 50%;
        text-align: center;
        font-size: 26px;
        color: white;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, .9);

        @media(max-width: 1000px) {
            max-width: 320px;
        }
    }
`;

const Title = styled.h3`
    text-align: center;
    margin: 0;
    color: ${COLORS.green};
    font-size: 30px;
`;

const TextWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 50px;
    color: ${COLORS.blue};
    font-size: 20px;
    text-align: justify;
    padding-top: 100px;

    .text {
        min-height: 170px;
    }
`;

const WaveDecor = styled.img`
    position: absolute;
    left: 0;
    width: 100%;
    top: 0;
    display: block;
    transform: rotateY(180deg);

    @media(max-width: 1000px) {
        width: 100%;
    }
`;

const Section = styled.section`
    padding: 0 0 80px;
`;

const UpperContent = styled.div`
    display: flex;
    justify-content: center;
    gap: 80px;
    padding-bottom: 30px;

    @media(max-width: 1000px) {
        display: grid;
        grid-template-columns: 1fr;
        gap: 15px;
    }
`;

const LowerContent = styled.div`
    display: flex;
    justify-content: space-between;

    @media(max-width: 1000px) {
        display: grid;
        grid-template-columns: 1fr;
        gap: 15px;
    }
`;

export default { ContentWrapper, Title, TextWrapper, WaveDecor, Section, UpperContent, LowerContent };
